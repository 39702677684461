import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import * as Styled from "./AdvertisementItem.styled";
import Typography from "@mui/material/Typography";

import defaultImage from "../../../assets/default.png";

const advertisementStatus = {
    active: {
        color: "success",
        title: "в наявності",
        icon: <DoneIcon />,
    },
    disabled: {
        color: "warning",
        title: "продано",
        icon: <CancelIcon />,
    },
    available: {
        color: "secondary",
        title: "показано",
        icon: <VisibilityIcon />,
    },
    unavailable: {
        color: "inherit",
        title: "приховано",
        icon: <VisibilityOffIcon />,
    },
};

export default function AdvertisementItem({
    advertisement,
    onDelete,
    onEdit,
    toggleActiveStatus,
    toggleAvailableStatus,
    loading,
}) {
    const [activeButtonProps, setActiveButtonProps] = useState(
        advertisement?.isActive
            ? advertisementStatus.active
            : advertisementStatus.disabled
    );

    const [availableButtonProps, setAvailableButtonProps] = useState(
        advertisement?.isAvailable
            ? advertisementStatus.available
            : advertisementStatus.unavailable
    );

    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

    const image =
        advertisement.images && advertisement.images.length > 0
            ? advertisement.images[0].url
            : defaultImage;

    function renderDate() {
        if (isMdUp) {
            return advertisement.updatedAt
                .toDate()
                .toLocaleDateString("uk-UA", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
        }

        return advertisement.updatedAt.toDate().toLocaleDateString("uk-UA");
    }

    function toggleAdvertisementActive() {
        const saleStatus = !advertisement.isActive;
        toggleActiveStatus(advertisement.id, saleStatus);

        if (saleStatus) setActiveButtonProps(advertisementStatus.active);
        else setActiveButtonProps(advertisementStatus.disabled);
        advertisement.isActive = saleStatus;
    }

    function toggleAdvertisementAvailable() {
        const availableStatus = !advertisement.isAvailable;
        toggleAvailableStatus(advertisement.id, availableStatus);

        if (availableStatus)
            setAvailableButtonProps(advertisementStatus.available);
        else setAvailableButtonProps(advertisementStatus.unavailable);
        advertisement.isAvailable = availableStatus;
    }

    return (
        <Styled.Container>
            <Styled.InfoContainer>
                <Styled.InfoImageContainer>
                    <Styled.InfoImage src={image} />
                </Styled.InfoImageContainer>
                <Styled.Info>
                    <Typography
                        variant={isMdUp ? "caption" : "body2"}
                        color="primary"
                        sx={{ textTransform: "uppercase" }}
                    >
                        {renderDate()}
                    </Typography>
                    <Typography variant="body2">
                        {advertisement.make || ""} {advertisement.model || ""}
                    </Typography>
                    <Typography variant="body2">
                        {advertisement.engine || ""} {advertisement.fuel || ""}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ textTransform: "uppercase" }}
                    >
                        {advertisement.year}
                    </Typography>
                </Styled.Info>
            </Styled.InfoContainer>
            <Styled.ControlsContainer>
                <Styled.Button
                    color={activeButtonProps.color}
                    variant="outlined"
                    sx={{ mb: 1 }}
                    startIcon={activeButtonProps.icon}
                    onClick={toggleAdvertisementActive}
                >
                    {activeButtonProps.title}
                </Styled.Button>
                <Styled.Button
                    color={availableButtonProps.color}
                    variant="outlined"
                    startIcon={availableButtonProps.icon}
                    onClick={toggleAdvertisementAvailable}
                >
                    {availableButtonProps.title}
                </Styled.Button>
            </Styled.ControlsContainer>
            <Styled.ControlsContainer>
                <Styled.Button
                    variant="outlined"
                    sx={{ mb: 1 }}
                    startIcon={<EditIcon />}
                    onClick={() => onEdit(advertisement.id)}
                >
                    редагувати
                </Styled.Button>
                <Styled.Button
                    color="error"
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    onClick={() => onDelete(advertisement)}
                    loading={loading ? "true" : "false"}
                >
                    видалити
                </Styled.Button>
            </Styled.ControlsContainer>
        </Styled.Container>
    );
}
