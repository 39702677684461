import React, { useContext } from "react";

import { useTheme } from "@mui/material/styles";
import { ColorModeContext } from "../../../styles/themeProvider";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../infrastructure/auth/auth";

import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import Logout from "@mui/icons-material/Logout";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";

import generateUserName from "../../../utils/generateUserName";
import * as Styled from "./ProfileMenu.styled";

export default function ProfileMenu() {
  const theme = useTheme();
  const themeContext = useContext(ColorModeContext);
  const auth = useAuth();

  let username;

  if (auth.user) {
    username = generateUserName(auth?.user.email);
  }

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Styled.Box>
        <Tooltip title="Налаштування акаунта">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Styled.Avatar>{username || ""}</Styled.Avatar>
          </IconButton>
        </Tooltip>
      </Styled.Box>
      <Styled.Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={themeContext.toggleColorMode}>
          <ListItemIcon>
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon fontSize="small" />
            ) : (
              <Brightness4Icon fontSize="small" />
            )}
          </ListItemIcon>
          {theme.palette.mode === "light" ? "Темний" : "Світлий"} режим
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() =>
            auth.signOut(() => navigate("/login", { replace: true }))
          }
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Вихід
        </MenuItem>
      </Styled.Menu>
    </>
  );
}
