import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import { useAuth } from "../../infrastructure/auth/auth";
import { EMAIL_REGEXP } from "../../constants/validation";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";

import AuthHeading from "../../components/ui/AuthHeading/AuthHeading";
import TradeMark from "../../components/ui/TradeMark/TradeMark";
import * as Styled from "./SignIn.styled";

export default function SignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  let from = location.state?.from?.pathname || "/";

  const onSubmit = async (data) => {
    setLoading(true);
    const { email, password } = data;
    try {
      await auth.signIn(email, password);
      setLoading(false);
      navigate(from, { replace: true });
    } catch (e) {
      setLoading(false);
      setError("auth", {
        type: "auth",
        message: "Неправільний логін або пароль",
      });
    }
  };

  function onFocusHandler() {
    if (errors && errors.auth) {
      clearErrors("auth");
    }
    return;
  }

  return (
    <Styled.Container sx={{ bgcolor: "background.default" }}>
      <TradeMark />
      <Styled.Paper elevation={3}>
        <AuthHeading>Вхід</AuthHeading>
        <Styled.AuthErrorContainer>
          {errors.auth && errors.auth.type === "auth" && (
            <Typography variant="body2" color="error">
              {errors.auth.message}
            </Typography>
          )}
        </Styled.AuthErrorContainer>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.FormContainer>
            <Styled.TextFieldContainer>
              <TextField
                id="outlined-error"
                label="Логін, або e-mail"
                sx={{ width: "100%" }}
                onFocus={onFocusHandler}
                {...register("email", {
                  required: true,
                  pattern: EMAIL_REGEXP,
                })}
              />
              {errors && errors.email && (
                <Styled.ErrorContainer>
                  {errors.email && errors.email.type === "required" && (
                    <Typography variant="body2" color="error">
                      Обов'язкове поле
                    </Typography>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <Typography variant="body2" color="error">
                      Невірний формат
                    </Typography>
                  )}
                </Styled.ErrorContainer>
              )}
            </Styled.TextFieldContainer>
            <Styled.TextFieldContainer>
              <TextField
                type={"password"}
                id="outlined-error"
                label="Пароль входу"
                sx={{ width: "100%" }}
                onFocus={onFocusHandler}
                {...register("password", {
                  required: true,
                  minLength: 6,
                  maxLength: 12,
                })}
              />
              {errors && errors.password && (
                <Styled.ErrorContainer>
                  {errors.password && errors.password.type === "required" && (
                    <Typography variant="body2" color="error">
                      Обов'язкове поле
                    </Typography>
                  )}
                  {errors.password && errors.password.type === "maxLength" && (
                    <Typography variant="body2" color="error">
                      Невірний формат
                    </Typography>
                  )}
                  {errors.password && errors.password.type === "minLength" && (
                    <Typography variant="body2" color="error">
                      Невірний формат
                    </Typography>
                  )}
                </Styled.ErrorContainer>
              )}
            </Styled.TextFieldContainer>
            <Styled.ForgotPasswordLinkContainer>
              <Link
                to="/reset-password"
                component={RouterLink}
                // color="textPrimary"
                variant="body2"
              >
                Забули пароль?
              </Link>
            </Styled.ForgotPasswordLinkContainer>

            <Styled.SignInButtonContainer>
              <Styled.SignInButton loading={loading}>
                Увійти
              </Styled.SignInButton>
            </Styled.SignInButtonContainer>

                    {/* TODO: Enable verification before give an access to user after sign up */}
            {/* <Styled.SignUpLinkContainer>
              <Typography color="textPrimary" variant="body2">
                Немає акаунта?{" "}
                <Link
                  to="/registration"
                  component={RouterLink}
                  // color="textPrimary"
                  variant="body2"
                >
                  Створити
                </Link>
              </Typography>
            </Styled.SignUpLinkContainer> */}
          </Styled.FormContainer>
        </Styled.Form>
      </Styled.Paper>
    </Styled.Container>
  );
}
