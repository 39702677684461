import { styled } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import ButtonMaterial from "@mui/material/Button";
import Box from "@mui/material/Box";

export const Form = styled("form")(({ theme }) => ({
    width: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}));

export const FormContainer = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
}));

export const TextFieldContainer = styled("div")(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

export const ColorMenuItem = styled("div")(() => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

export const ColorContainer = styled("div")(({ color }) => ({
    width: 30,
    height: 20,
    background: color,
}));

export const ErrorContainer = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
}));

export const AddButtonContainer = styled("div")(({ theme }) => ({
    width: "100%",
    paddingTop: theme.spacing(5),
}));

export const NavigationContainer = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
    marginTop: 20,
}));

export const NavigationWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
}));

export const CropperNavigation = styled(Box)(({ theme }) => ({
    position: "absolute",
    bottom: "-5%",
    background: "#fff",
    width: "70%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    justifyContent: "center",
    padding: "20px 20px 30px",
    [theme.breakpoints.down("md")]: {
        bottom: "-10%",
    },
}));

export const CropperContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "50%",
    bgcolor: "#fff",
    boxShadow: 24,
    p: 8,
}));

export const AddButton = (props) => (
    <LoadingButton
        sx={{ width: "100%" }}
        type="submit"
        variant="contained"
        loading={props.loading ? "true" : "false"}
        {...props}
    >
        {props.children}
    </LoadingButton>
);

export const LoadedImagesContainer = styled("div")(({ theme }) => ({
    display: "flex",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
}));

export const LoadedImage = styled("div")(({ theme }) => ({
    position: "relative",
    width: "48%",
    marginBottom: theme.spacing(2),
    color: "#ccc",
}));

export const DeleteButton = styled(IconButton)(() => ({
    position: "absolute",
    right: -5,
    top: -5,
}));

export const Button = styled(ButtonMaterial)(({ theme }) => ({
    width: 150,
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}));
