import Router from "./infrastructure/navigation/router";
import AuthProvider from "./infrastructure/auth/auth";
import { ThemeProvider } from "./styles/themeProvider";
import "normalize.css";

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
