import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Cropper from "react-easy-crop";
import Compressor from "compressorjs";
import heic2any from "heic2any";

import { storage, saveAdvertisement } from "../../infrastructure/firebase";
import { v4 as uuidv4 } from "uuid";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import getCroppedImg from "../../utils/cropImage";

import { useForm } from "react-hook-form";

import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Cancel";
import Modal from "@mui/material/Modal";
import Slider from "@mui/material/Slider";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import makes from "../../data/makes.json";
import years from "../../data/years.json";
import transmissions from "../../data/transmissions.json";
import fuelTypes from "../../data/fuel.json";
import driveTypes from "../../data/drive.json";
import colors from "../../data/colors.json";

import * as Styled from "./AddAdvertisement.styled";

export default function AddAdvertisement() {
  const [models, setModels] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    resetCroppedImage();
  };
  const [imgLoader, setImgLoader] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [loadedPictures, setLoadedPictures] = useState([]);

  const getImageUrls = async (e) => {
    const imageUrls = [];

    if (!loadedPictures.length) return [];

    for (let value of loadedPictures) {
      const { file, id } = value;
      const storageRef = ref(storage, `images/${id}`);

      await uploadBytes(storageRef, file);
      const uploadUrl = await getDownloadURL(storageRef);
      imageUrls.push({ id, url: uploadUrl });
    }

    return imageUrls;
  };

  async function convertImage(file, setCroppedImage) {
    const blobURL = URL.createObjectURL(file);
    await fetch(blobURL)
      .then((res) => res.blob())
      .then((blob) => heic2any({ blob }))
      .then((conversionResult) => {
        const url = URL.createObjectURL(conversionResult);
        setCroppedImage(url);
      })
      .catch((e) => {});
  }

  const handleCompressImage = async (file) => {
    return new Promise((resolve) => {
      new Compressor(file, {
        quality: 0.6,
        // convertSize: 5000,

        success(result) {
          resolve(result);
        },
      });
    });
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];

    setImgLoader(true);
    const compressedBlob = await handleCompressImage(file);
    setOpen(true);
    if (!file.type) {
      await convertImage(compressedBlob, setCroppedImage);
      setImgLoader(false);
    } else {
      const blobUrl = URL.createObjectURL(compressedBlob);
      setCroppedImage(blobUrl);
      setImgLoader(false);
    }
  };

  function removePreviewHandler(id) {
    const previews = [...loadedPictures];
    const filteredPreviews = previews.filter((picture) => picture.id !== id);
    setLoadedPictures(filteredPreviews);
  }

  const onSubmit = async (data) => {
    const id = uuidv4();
    setLoading(true);
    const images = await getImageUrls();

    const currentDate = new Date();

    const advertisement = {
      ...data,
      id,
      images,
      createdAt: currentDate,
      updatedAt: currentDate,
      isActive: true,
      isAvailable: true,
      year: parseInt(data.year),
      price: parseInt(data.price),
      newPrice: parseInt(data.newPrice) || 0,
      odometer: parseInt(data.odometer) || 0,
    };

    saveAdvertisement(advertisement);
    setLoadedPictures([]);
    setLoading(false);
    navigate("/", { replace: true });
  };

  React.useEffect(() => {
    const subscription = watch(({ make }) => {
      const carModels = makes[make];
      setModels(carModels);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = React.useCallback(async () => {
    const previews = [...loadedPictures];

    try {
      const file = await getCroppedImg(croppedImage, croppedAreaPixels, 0);
      setCroppedImage(file);

      const id = uuidv4();
      const url = URL.createObjectURL(file);

      previews.push({ id, url, file });

      setLoadedPictures([...previews]);
      resetCroppedImage();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const resetCroppedImage = () => {
    inputRef.current.value = null;
    setOpen(false);
    setCroppedImage(null);
    setCroppedImage(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  return (
    <>
      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <Styled.FormContainer>
          <Button
            startIcon={<CameraIcon />}
            component="label"
            style={
              loadedPictures.length >= 10
                ? { backgroundColor: "grey" }
                : { visibility: "visible" }
            }
            variant="contained"
          >
            Додати фото
            <input
              hidden
              ref={inputRef}
              type="file"
              onChange={handleFileInputChange}
              accept="image/jpeg, image/jpg, image/png, image/heic"
              disabled={loadedPictures.length >= 10}
            />
          </Button>
          <Styled.LoadedImagesContainer>
            {loadedPictures.length > 0 &&
              loadedPictures.map(({ id, url }) => (
                <Styled.LoadedImage key={id}>
                  <Styled.DeleteButton
                    aria-label="delete"
                    size="large"
                    onClick={() => removePreviewHandler(id)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </Styled.DeleteButton>
                  <img src={url} alt="current file" style={{ width: "100%" }} />
                </Styled.LoadedImage>
              ))}
          </Styled.LoadedImagesContainer>
          <Styled.TextFieldContainer>
            <TextField
              size="small"
              select
              id="outlined-error"
              label="Марка"
              sx={{ width: "100%" }}
              {...register("make", {
                required: true,
              })}
            >
              {Object.keys(makes).map((make) => (
                <MenuItem key={make} value={make}>
                  {make}
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.make && (
              <Styled.ErrorContainer>
                {errors.make && errors.make.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              size="small"
              select
              id="outlined-error"
              label="Модель"
              sx={{ width: "100%" }}
              disabled={Boolean(!models || !models.length)}
              {...register("model", {
                required: true,
              })}
              children={() =>
                models &&
                models.map((model) => (
                  <MenuItem key={model} value={model}>
                    {model}
                  </MenuItem>
                ))
              }
            >
              {models &&
                models.map((model) => (
                  <MenuItem key={model} value={model}>
                    {model}
                  </MenuItem>
                ))}
            </TextField>
            {errors && errors.model && (
              <Styled.ErrorContainer>
                {errors.model && errors.model.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              size="small"
              select
              id="outlined-error"
              label="Рік випуску"
              sx={{ width: "100%" }}
              {...register("year", {
                required: true,
              })}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.year && (
              <Styled.ErrorContainer>
                {errors.year && errors.year.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              size="small"
              select
              id="outlined-error"
              label="Коробка передач"
              sx={{ width: "100%" }}
              {...register("transmission", {
                required: true,
              })}
            >
              {transmissions.map((transmission) => (
                <MenuItem key={transmission} value={transmission}>
                  {transmission}
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.transmission && (
              <Styled.ErrorContainer>
                {errors.transmission &&
                  errors.transmission.type === "required" && (
                    <Typography variant="body2" color="error">
                      Обов'язкове поле
                    </Typography>
                  )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              size="small"
              select
              id="outlined-error"
              label="Тип приводу"
              sx={{ width: "100%" }}
              {...register("drive", {
                required: true,
              })}
            >
              {driveTypes.map((drive) => (
                <MenuItem key={drive} value={drive}>
                  {drive}
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.drive && (
              <Styled.ErrorContainer>
                {errors.drive && errors.drive.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              size="small"
              select
              id="outlined-error"
              label="Тип палива"
              sx={{ width: "100%" }}
              {...register("fuel", {
                required: true,
              })}
            >
              {fuelTypes.map((fuel) => (
                <MenuItem key={fuel} value={fuel}>
                  {fuel}
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.fuel && (
              <Styled.ErrorContainer>
                {errors.fuel && errors.fuel.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              size="small"
              select
              id="outlined-error"
              label="Колір"
              sx={{ width: "100%" }}
              {...register("color", {
                required: true,
              })}
            >
              {colors.map(({ name, color }) => (
                <MenuItem key={name} value={name}>
                  <Styled.ColorMenuItem>
                    {name}
                    <Styled.ColorContainer
                      color={color}
                    ></Styled.ColorContainer>
                  </Styled.ColorMenuItem>
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.fuel && (
              <Styled.ErrorContainer>
                {errors.fuel && errors.fuel.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              id="outlined-basic"
              label="Об'єм двигуна (л)"
              variant="outlined"
              size="small"
              sx={{ width: "100%" }}
              {...register("engine", {
                required: true,
              })}
            />
            {errors && errors.drive && (
              <Styled.ErrorContainer>
                {errors.drive && errors.drive.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              id="outlined-basic"
              label="Пробіг (км)"
              variant="outlined"
              size="small"
              sx={{ width: "100%" }}
              {...register("odometer", {
                required: true,
              })}
            />
            {errors && errors.odometer && (
              <Styled.ErrorContainer>
                {errors.odometer && errors.odometer.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              id="outlined-basic"
              label="Посилання на відео (url)"
              variant="outlined"
              size="small"
              sx={{ width: "100%" }}
              {...register("youtubeUrl", {
                required: false,
              })}
            />
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              id="outlined-basic"
              label="Опис (1000 символів)"
              multiline
              rows={4}
              variant="outlined"
              size="small"
              sx={{ width: "100%" }}
              {...register("description", {
                required: true,
              })}
            />
            {errors && errors.description && (
              <Styled.ErrorContainer>
                {errors.description && errors.description.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              id="outlined-basic"
              label="Ціна"
              variant="outlined"
              size="small"
              sx={{ width: "100%" }}
              {...register("price", {
                required: true,
              })}
            />
            {errors && errors.price && (
              <Styled.ErrorContainer>
                {errors.price && errors.price.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              id="outlined-basic"
              label="Нова ціна"
              variant="outlined"
              size="small"
              sx={{ width: "100%" }}
              {...register("newPrice", {
                required: false,
              })}
            />
          </Styled.TextFieldContainer>
          <Styled.AddButtonContainer>
            <Styled.AddButton loading={loading}>
              Додати оголошення
            </Styled.AddButton>
          </Styled.AddButtonContainer>
        </Styled.FormContainer>
      </Styled.Form>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Styled.CropperContainer>
              {imgLoader ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Cropper
                  image={croppedImage}
                  crop={crop}
                  zoom={zoom}
                  aspect={16 / 9}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              )}
            </Styled.CropperContainer>
            <Styled.CropperNavigation>
              <Styled.NavigationWrapper>
                <Slider
                  size="small"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                  valueLabelDisplay="auto"
                  sx={{ maxWidth: 200 }}
                />
                <Styled.NavigationContainer>
                  <Styled.Button
                    disabled={imgLoader}
                    variant="outlined"
                    sx={{
                      "&.Mui-disabled": {
                        background: "#eaeaea",
                        color: "#c0c0c0",
                      },
                    }}
                    onClick={showCroppedImage}
                  >
                    Додати
                  </Styled.Button>
                  <Styled.Button
                    sx={{ m: 1 }}
                    color="error"
                    variant="outlined"
                    onClick={handleClose}
                  >
                    Скасувати
                  </Styled.Button>
                </Styled.NavigationContainer>
              </Styled.NavigationWrapper>
            </Styled.CropperNavigation>
          </div>
        </Modal>
      </div>
    </>
  );
}
