import React from "react";
import * as Styled from "./AuthHeading.styled";

export default function AuthHeading({ children }) {
  return (
    <Styled.Container>
      <Styled.AvatarContainer>
        <Styled.Avatar />
      </Styled.AvatarContainer>
      <Styled.Title>{children}</Styled.Title>
    </Styled.Container>
  );
}
