import { styled } from "@mui/system";
import {
  Drawer as DrawerMaterial,
  AppBar as AppBarMaterial,
  Toolbar as ToolbarMaterial,
  IconButton,
} from "@mui/material";

const drawerWidth = 260;

export const Root = styled("div")(({ theme }) => ({
  display: "flex",
}));

export const AppBar = styled(AppBarMaterial)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export const AppBarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: theme.spacing(2),
}));

export const Drawer = styled(DrawerMaterial)(({ theme }) => ({
  ...theme.mixins.drawer,
  flexShrink: 0,
  width: drawerWidth,
}));
export const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));
export const Toolbar = styled(ToolbarMaterial)(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

export const Content = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "90vh",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
  marginTop: theme.mixins.toolbar.minHeight,
}));

export const DrawerContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  justifyContent: "space-between",
  paddingTop: theme.mixins.toolbar.minHeight + 18,
  width: drawerWidth,
}));
