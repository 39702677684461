import { styled } from "@mui/system";
import { Box as BoxMaterial } from "@mui/material";

export const Container = styled(BoxMaterial)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  position: "relative",
}));

export const Content = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  width: "100%",
}));

export const LogoContainer = styled("div")(({ theme }) => ({
  width: 20,
  height: 20,
}));

export const Logo = styled("img")(() => ({
  width: 20,
  height: 20,
}));

export const LabelContainer = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));
