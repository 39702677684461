import { styled } from "@mui/system";
import {
  Box as BoxMaterial,
  Menu as MenuMaterial,
  Avatar as AvatarMaterial,
} from "@mui/material";

export const Box = styled(BoxMaterial)(() => ({
  display: "flex",
  alignItems: "center",
  textAlign: "center",
}));

export const Avatar = styled(AvatarMaterial)(() => ({
  width: 35,
  height: 35,
}));

export const Menu = (props) => (
  <MenuMaterial
    id="account-menu"
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "& .MuiAvatar-root": {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
    transformOrigin={{ horizontal: "right", vertical: "top" }}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    {...props}
  >
    {props.children}
  </MenuMaterial>
);
