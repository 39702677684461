import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    getAdvertisementList,
    deleteAdvertisement,
    updateAdvertisement,
    getAdvertisement,
    getAdvertisementListLength,
} from "../../infrastructure/firebase";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Modal from "@mui/material/Modal";

import AdvertisementItem from "./components/AdvertisementItem";
import * as Styled from "./components/AdvertisementItem.styled";

export default function AdvertisimentList() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const [advertisementList, setAdvertisementList] = useState([]);
    const [selectedAds, setSelectedAds] = useState(null);

    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 5;

    const handleOpen = (advertisement) => {
        setOpen(true);
        setSelectedAds(advertisement);
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedAds(null);
    };

    useEffect(() => {
        getAdvertisementList(setAdvertisementList, itemsPerPage);
        getAdvertisementListLength(setTotalPagesHandler);
    }, []);

    function setTotalPagesHandler(count) {
        setTotalPages(Math.ceil(count / itemsPerPage));
    }

    async function deleteAdvertisementHandler(advertisement) {
        setLoading(true);

        try {
            await deleteAdvertisement(advertisement);
            await getAdvertisementList(
                setAdvertisementList,
                itemsPerPage,
                currentPage - 1
            );
            await getAdvertisementListLength(setTotalPagesHandler);

            setLoading(false);
        } catch (e) {
            console.log("Error delete ", e);
            setLoading(false);
        }
        handleClose();
    }

    function editAdvertisementHandler(advertisementId) {
        navigate(`/edit-advertisement/${advertisementId}`);
    }

    function toggleActiveStatus(advertisementId, activeStatus) {
        function updateAdvertisementHandler(advs) {
            updateAdvertisement({ ...advs, isActive: activeStatus });
        }
        getAdvertisement(advertisementId, updateAdvertisementHandler);
    }

    function toggleAvailableStatus(advertisementId, availableStatus) {
        function updateAdvertisementHandler(advs) {
            updateAdvertisement({ ...advs, isAvailable: availableStatus });
        }
        getAdvertisement(advertisementId, updateAdvertisementHandler);
    }

    function changePageHandler(page) {
        setCurrentPage(page);
        getAdvertisementList(setAdvertisementList, itemsPerPage, page - 1);
    }

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Stack spacing={2}>
                {advertisementList.length > 0 ? (
                    advertisementList.map((advertisement) => (
                        <AdvertisementItem
                            key={advertisement.id}
                            advertisement={advertisement}
                            onDelete={handleOpen}
                            onEdit={editAdvertisementHandler}
                            toggleActiveStatus={toggleActiveStatus}
                            toggleAvailableStatus={toggleAvailableStatus}
                            loading={loading}
                        />
                    ))
                ) : (
                    <Box
                        sx={{
                            mt: 20,
                            p: 2,
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="body2" color="textSecondary">
                            Активних оголошень немає
                        </Typography>
                    </Box>
                )}
                {advertisementList.length > 0 && (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Pagination
                            variant="outlined"
                            color="primary"
                            count={totalPages}
                            page={currentPage}
                            onChange={(event, page) => changePageHandler(page)}
                            showFirstButton
                            showLastButton
                        />
                    </Box>
                )}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Styled.ModalBox>
                        <Styled.Container>
                            <Typography
                                variant={"caption"}
                                sx={{
                                    textTransform: "uppercase",
                                    fontSize: 18,
                                }}
                            >
                                Ви впевнені що хочете видалити оголошення?
                            </Typography>

                            <Styled.Button
                                variant="outlined"
                                color="error"
                                sx={{ mb: 1 }}
                                onClick={() =>
                                    deleteAdvertisementHandler(selectedAds)
                                }
                            >
                                Видалити
                            </Styled.Button>
                            <Styled.Button
                                variant="outlined"
                                sx={{ mb: 1 }}
                                onClick={() => handleClose()}
                            >
                                Скасувати
                            </Styled.Button>
                        </Styled.Container>
                    </Styled.ModalBox>
                </Modal>
            </Stack>
        </Box>
    );
}
