import { styled } from "@mui/system";

export const Container = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: "0",
  right: "0",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
