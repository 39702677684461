import { styled } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import { Paper as MaterialPaper } from "@mui/material";

export const Container = styled("div")({
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const Paper = styled(MaterialPaper)(({ theme }) => ({
  width: "80%",
  padding: theme.spacing(3),
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(7),
  [theme.breakpoints.up("md")]: {
    width: "30%",
    padding: theme.spacing(8),
  },
}));

export const Form = styled("form")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const FormContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1),
}));

export const TextFieldContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const ErrorContainer = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(2),
}));

export const AuthErrorContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingBottom: theme.spacing(2),
}));

export const ForgotPasswordLinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(4),
}));

export const SignInButtonContainer = styled("div")(({ theme }) => ({
  width: "100%",
  paddingBottom: theme.spacing(),
}));

export const SignInButton = (props) => (
  <LoadingButton
    sx={{ width: "100%" }}
    type="submit"
    variant="contained"
    {...props}
  >
    {props.children}
  </LoadingButton>
);

export const SignUpLinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(2),
  boxSizing: "border-box",
}));
