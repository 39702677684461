import React, { useState, useMemo, createContext } from "react";
import {
  ThemeProvider as MaterialThemeProvider,
  createTheme,
} from "@mui/material/styles";

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export function ThemeProvider({ children }) {
  const [mode, setMode] = useState(() => {
    let mode = localStorage.getItem("mode");

    if (!mode) {
      localStorage.setItem("mode", "light");
      mode = "light";
    }
    return mode;
  });

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const mode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("mode", mode);
          return mode;
        });
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
    </ColorModeContext.Provider>
  );
}
