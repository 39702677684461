// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, deleteObject, ref as sRef } from "firebase/storage";
import {
  getFirestore,
  collection,
  setDoc,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  orderBy,
  query,
  limit,
  startAt,
  getCountFromServer,
  getDocs,
  increment,
  Timestamp,
} from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  getAuth,
  signOut,
} from "firebase/auth";
import advsMock from "../data/advs.json";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDX_E8ZY2B0oBTuZGglNpI8ZqYBx127YcM",
  authDomain: "metra-client-app-database.firebaseapp.com",
  databaseURL: "https://metra-client-app-database-default-rtdb.firebaseio.com",
  projectId: "metra-client-app-database",
  storageBucket: "metra-client-app-database.appspot.com",
  messagingSenderId: "536063250734",
  appId: "1:536063250734:web:1d2f3d41683ea16d162abf",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
const db = getFirestore(app);

const auth = getAuth();

export function signInHandler(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function signUpHandler(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function signOutHandler() {
  return signOut(auth)
    .then((res) => res)
    .catch((e) => console.error("sign out error ", e));
}

export function sendResetPasswordEmailHandler(email) {
  return sendPasswordResetEmail(auth, email);
}

export function sendEmailVerificationHandler(user) {
  return sendEmailVerification(user)
    .then(() => {
      console.log("Verification email sent!");
    })
    .catch((e) => {
      console.error("sent verification email error ", e);
    });
}

export async function getAdvertisementListLength(cb) {
  return await getCountFromServer(collection(db, "advs")).then(
    (querySnapshot) => {
      cb(querySnapshot.data().count);
    }
  );
}

export async function saveAdvertisement(data) {
  await setDoc(doc(db, "advs", data.id), data);
  return await updateDoc(doc(db, "makes", data.make), {
    count: increment(1),
    [`models.${data.model}`]: increment(1),
  });
}

export async function getDocumentAtIndex(index) {
  const querySnapshot = await getDocs(
    query(
      collection(db, "advs"),
      orderBy("updatedAt", "desc"),
      limit(index + 1)
    )
  );
  return querySnapshot.docs[index];
}

export async function getAdvertisementList(cb, lim = 5, start = 0) {
  try {
    // updateAdvertisementListFromMock();
    const startDoc = await getDocumentAtIndex(start * lim);
    const carsQ = query(
      collection(db, "advs"),
      orderBy("updatedAt", "desc"),
      startAt(startDoc),
      limit(lim)
    );
    const querySnapshot = await getDocs(carsQ);

    const data = [];
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    cb(data);
  } catch (error) {
    console.log("Error getting documents: ", error);
    cb([]);
  }
}

export async function getAdvertisement(id, cb) {
  return await getDoc(doc(db, "advs", id))
    .then((doc) => {
      if (doc.exists()) {
        cb(doc.data());
      } else {
        console.error("No such document!");
        cb(null);
      }
    })
    .catch((error) => {
      console.error("Error getting document:", error);
      cb(null);
    });
}

export async function deleteAdvertisement(advertisement) {
  if (advertisement.images?.length) {
    for (let image of advertisement.images) {
      deleteImage(image.id);
    }
  }
  await deleteDoc(doc(db, "advs", advertisement.id));
  return await updateDoc(doc(db, "makes", advertisement.make), {
    count: increment(-1),
    [`models.${advertisement.model}`]: increment(-1),
  });
}

export async function updateAdvertisement(advertisement) {
  return await updateDoc(doc(db, "advs", advertisement.id), advertisement);
}

export async function deleteImage(id) {
  const imageRef = sRef(storage, `images/${id}`);
  return await deleteObject(imageRef);
}

// function updateAdvertisementListFromMock() {
//   // console.log(advsMock);
//   advsMock.forEach(
//     async (adv) =>
//       await setDoc(
//         doc(db, "advs", adv.id),
//         {
//           ...adv,
//           updatedAt: Timestamp.fromDate(new Date(adv.updatedAt)),
//           createdAt: Timestamp.fromDate(new Date(adv.createdAt)),
//           odometer: parseInt(adv.odometer),
//           price: parseInt(adv.price),
//           newPrice: parseInt(adv.newPrice || 0),
//           year: parseInt(adv.year),
//         },
//         { merge: true }
//       )
//   );
// }
