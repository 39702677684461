import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "react-hook-form";

import Cropper from "react-easy-crop";
import Compressor from "compressorjs";
import heic2any from "heic2any";

import {
  getAdvertisement,
  updateAdvertisement,
  storage,
  deleteImage,
} from "../../infrastructure/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Slider from "@mui/material/Slider";

import makes from "../../data/makes.json";
import years from "../../data/years.json";
import transmissions from "../../data/transmissions.json";
import fuelTypes from "../../data/fuel.json";
import driveTypes from "../../data/drive.json";
import colors from "../../data/colors.json";

import MenuItem from "@mui/material/MenuItem";

import * as Styled from "./EditAdvertisement.styled";
import getCroppedImg from "../../utils/cropImage";

export default function EditAdvertisement() {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    resetCroppedImage();
  };

  const [models, setModels] = React.useState([]);
  const [ads, setAds] = useState(null);
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImgLoader] = useState(false);

  const [temporaryDeleted, setTemporaryDeleted] = useState([]);

  const params = useParams();

  const onSubmit = async (data) => {
    setLoader(true);
    const currentAds = { ...ads };
    const {
      make,
      model,
      year,
      transmission,
      drive,
      fuel,
      color,
      engine,
      odometer,
      description,
      price,
      youtubeUrl,
      newPrice,
    } = data;

    if (temporaryDeleted.length) {
      for (let imageId of temporaryDeleted) {
        deleteImage(imageId);
      }
    }
    const images = await generateImages();
    const currentDate = new Date();

    await updateAdvertisement({
      ...currentAds,
      make,
      model,
      images,
      transmission,
      drive,
      fuel,
      color,
      engine,
      description,
      updatedAt: currentDate,
      youtubeUrl,
      year: parseInt(year),
      price: parseInt(price),
      newPrice: parseInt(newPrice) || 0,
      odometer: parseInt(odometer) || 0,
    });
    setLoader(false);
    navigate("/");
  };
  async function convertImage(file, setCroppedImage) {
    const blobURL = URL.createObjectURL(file);
    await fetch(blobURL)
      .then((res) => res.blob())
      .then((blob) => heic2any({ blob }))
      .then((conversionResult) => {
        const url = URL.createObjectURL(conversionResult);
        setCroppedImage(url);
      })
      .catch((e) => {});
  }

  const handleCompressImage = async (file) => {
    return new Promise((resolve) => {
      new Compressor(file, {
        quality: 0.6,
        // convertSize: 5000,

        success(result) {
          resolve(result);
        },
      });
    });
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];

    setImgLoader(true);
    const compressedBlob = await handleCompressImage(file);
    setOpen(true);
    if (!file.type) {
      await convertImage(compressedBlob, setCroppedImage);
      setImgLoader(false);
    } else {
      const blobUrl = URL.createObjectURL(compressedBlob);
      setCroppedImage(blobUrl);
      setImgLoader(false);
    }
  };

  useEffect(() => {
    getAdvertisement(params.id, setAds);
  }, []);

  function deleteImageHandler(image) {
    const { id } = image;
    const deleted = [...temporaryDeleted];
    const currentAds = { ...ads };
    const currentImages = currentAds.images;

    if (!image.file) deleted.push(id);

    const filteredImages = currentImages.filter((image) => image.id !== id);
    const updatedAds = { ...ads, images: filteredImages };

    setAds(updatedAds);
    setTemporaryDeleted(deleted);
  }

  async function generateImages() {
    const currentAds = { ...ads };
    const images = currentAds?.images;

    let temporaryImages = [];
    const generatedImages = images.filter((image) => !image.file);

    for (let image of ads.images) {
      if (image.file) {
        const { file, id } = image;
        const storageRef = ref(storage, `images/${id}`);

        await uploadBytes(storageRef, file);
        const uploadUrl = await getDownloadURL(storageRef);

        temporaryImages.push({ id, url: uploadUrl });
      }
    }

    const concatedImages = temporaryImages.concat(generatedImages);
    return concatedImages;
  }

  React.useEffect(() => {
    const subscription = watch(({ make }) => {
      const carModels = makes[make];
      setModels(carModels);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {}, [models]);

  React.useEffect(() => {
    if (ads?.make) {
      setValue("model", ads.model);
    }
  }, [ads]);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = React.useCallback(async () => {
    const currentAds = { ...ads };
    const images = currentAds.images;

    try {
      const file = await getCroppedImg(croppedImage, croppedAreaPixels, 0);
      setCroppedImage(file);

      const id = uuidv4();
      const url = URL.createObjectURL(file);

      images.push({ id, url, file });

      setAds({ ...ads, images });
      inputRef.current.value = null;
      resetCroppedImage();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const resetCroppedImage = () => {
    inputRef.current.value = null;
    setOpen(false);
    setCroppedImage(null);
    setCroppedImage(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  return ads || loader ? (
    <>
      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <Button
          startIcon={<CameraIcon />}
          component="label"
          sx={{ width: "100%" }}
          variant="contained"
          style={
            ads.images?.length >= 10
              ? { backgroundColor: "grey" }
              : { visibility: "visible" }
          }
        >
          Додати фото
          <input
            hidden
            disabled={ads.images?.length >= 10}
            ref={inputRef}
            type="file"
            onChange={handleFileInputChange}
            accept="image/jpeg, image/jpg, image/png, image/heic"
          />
        </Button>
        <Styled.LoadedImagesContainer
          style={
            window.matchMedia("(max-width: 768px)").matches
              ? { justifyContent: "space-around" }
              : { justifyContent: "flex-start" }
          }
        >
          {ads.images?.length > 0 &&
            ads.images.map((image) => (
              <Styled.LoadedImageBlock key={image.id}>
                <Styled.LoadedImage>
                  <Styled.DeleteButton
                    aria-label="delete"
                    size="large"
                    onClick={() => deleteImageHandler(image)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </Styled.DeleteButton>
                  <img
                    src={image.url}
                    alt="current file"
                    style={{ width: "100%" }}
                  />
                </Styled.LoadedImage>
              </Styled.LoadedImageBlock>
            ))}
        </Styled.LoadedImagesContainer>
        <Styled.FormContainer
          style={
            window.matchMedia("(max-width: 768px)").matches
              ? { justifyContent: "space-around" }
              : { justifyContent: "space-between" }
          }
        >
          <Styled.TextFieldContainer>
            <TextField
              defaultValue={ads.make}
              size="small"
              select
              id="outlined-error"
              label="Марка"
              sx={{ width: "100%" }}
              {...register("make", {
                required: true,
              })}
            >
              {Object.keys(makes).map((make) => (
                <MenuItem key={make} value={make}>
                  {make}
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.make && (
              <Styled.ErrorContainer>
                {errors.make && errors.make.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              defaultValue={ads.model}
              size="small"
              select
              id="outlined-error"
              label="Модель"
              sx={{ width: "100%" }}
              disabled={Boolean(!ads.model || !models || !models.length)}
              {...register("model", {
                required: true,
              })}
            >
              {models &&
                models.map((model) => (
                  <MenuItem key={model} value={model}>
                    {model}
                  </MenuItem>
                ))}
            </TextField>
            {errors && errors.model && (
              <Styled.ErrorContainer>
                {errors.model && errors.model.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              defaultValue={ads.year}
              size="small"
              select
              id="outlined-error"
              label="Рік випуску"
              sx={{ width: "100%" }}
              {...register("year", {
                required: true,
              })}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.year && (
              <Styled.ErrorContainer>
                {errors.year && errors.year.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              defaultValue={ads.transmission}
              size="small"
              select
              id="outlined-error"
              label="Коробка передач"
              sx={{ width: "100%" }}
              {...register("transmission", {
                required: true,
              })}
            >
              {transmissions.map((transmission) => (
                <MenuItem key={transmission} value={transmission}>
                  {transmission}
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.transmission && (
              <Styled.ErrorContainer>
                {errors.transmission &&
                  errors.transmission.type === "required" && (
                    <Typography variant="body2" color="error">
                      Обов'язкове поле
                    </Typography>
                  )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              defaultValue={ads.drive}
              size="small"
              select
              id="outlined-error"
              label="Тип приводу"
              sx={{ width: "100%" }}
              {...register("drive", {
                required: true,
              })}
            >
              {driveTypes.map((drive) => (
                <MenuItem key={drive} value={drive}>
                  {drive}
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.drive && (
              <Styled.ErrorContainer>
                {errors.drive && errors.drive.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              defaultValue={ads.fuel}
              size="small"
              select
              id="outlined-error"
              label="Тип палива"
              sx={{ width: "100%" }}
              {...register("fuel", {
                required: true,
              })}
            >
              {fuelTypes.map((fuel) => (
                <MenuItem key={fuel} value={fuel}>
                  {fuel}
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.fuel && (
              <Styled.ErrorContainer>
                {errors.fuel && errors.fuel.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              defaultValue={ads.color}
              size="small"
              select
              id="outlined-error"
              label="Колір"
              sx={{ width: "100%" }}
              {...register("color", {
                required: true,
              })}
            >
              {colors.map(({ name, color }) => (
                <MenuItem key={name} value={name}>
                  <Styled.ColorMenuItem>
                    {name}
                    <Styled.ColorContainer
                      color={color}
                    ></Styled.ColorContainer>
                  </Styled.ColorMenuItem>
                </MenuItem>
              ))}
            </TextField>
            {errors && errors.fuel && (
              <Styled.ErrorContainer>
                {errors.fuel && errors.fuel.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              defaultValue={ads.engine}
              id="outlined-basic"
              label="Об'єм двигуна (л)"
              variant="outlined"
              size="small"
              sx={{ width: "100%" }}
              {...register("engine", {
                required: true,
              })}
            />
            {errors && errors.engine && (
              <Styled.ErrorContainer>
                {errors.engine && errors.engine.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              defaultValue={ads.odometer}
              id="outlined-basic"
              label="Пробіг (км)"
              variant="outlined"
              size="small"
              sx={{ width: "100%" }}
              {...register("odometer", {
                required: true,
              })}
            />
            {errors && errors.odometer && (
              <Styled.ErrorContainer>
                {errors.odometer && errors.odometer.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              defaultValue={ads.price}
              id="outlined-basic"
              label="Ціна"
              variant="outlined"
              size="small"
              sx={{ width: "100%" }}
              {...register("price", {
                required: true,
              })}
            />
            {errors && errors.price && (
              <Styled.ErrorContainer>
                {errors.price && errors.price.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              defaultValue={ads.youtubeUrl}
              id="outlined-basic"
              label="Посилання на відео (url)"
              variant="outlined"
              size="small"
              sx={{ width: "100%" }}
              {...register("youtubeUrl", {
                required: false,
              })}
            />
          </Styled.TextFieldContainer>
          <Styled.TextFieldContainer>
            <TextField
              multiline={true}
              rows={6}
              defaultValue={ads.description}
              id="outlined-basic"
              label="Опис (1000 символів)"
              variant="outlined"
              size="small"
              sx={{ width: "100%" }}
              {...register("description", {
                required: true,
              })}
            />
            {errors && errors.description && (
              <Styled.ErrorContainer>
                {errors.description && errors.description.type === "required" && (
                  <Typography variant="body2" color="error">
                    Обов'язкове поле
                  </Typography>
                )}
              </Styled.ErrorContainer>
            )}
          </Styled.TextFieldContainer>
          <TextField
            defaultValue={ads.newPrice}
            id="outlined-basic"
            label="Нова ціна"
            variant="outlined"
            size="small"
            sx={{ width: "100%" }}
            {...register("newPrice", {
              required: false,
            })}
          />
        </Styled.FormContainer>
        <LoadingButton
          color="primary"
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          type="submit"
          sx={{ width: "100%" }}
        >
          <span>Зберегти</span>
        </LoadingButton>
      </Styled.Form>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <Styled.CropperContainer>
              {imgLoader ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Cropper
                  image={croppedImage}
                  crop={crop}
                  zoom={zoom}
                  aspect={16 / 9}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              )}
            </Styled.CropperContainer>
            <Styled.CropperNavigation>
              <Styled.NavigationWrapper>
                <Slider
                  size="small"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                  valueLabelDisplay="auto"
                  sx={{ maxWidth: 200 }}
                />
                <Styled.NavigationContainer>
                  <Styled.Button
                    disabled={imgLoader}
                    variant="outlined"
                    sx={{
                      "&.Mui-disabled": {
                        background: "#eaeaea",
                        color: "#c0c0c0",
                      },
                    }}
                    onClick={showCroppedImage}
                  >
                    Додати
                  </Styled.Button>
                  <Styled.Button
                    sx={{ m: 1 }}
                    color="error"
                    variant="outlined"
                    onClick={handleClose}
                  >
                    Скасувати
                  </Styled.Button>
                </Styled.NavigationContainer>
              </Styled.NavigationWrapper>
            </Styled.CropperNavigation>
          </div>
        </Modal>
      </div>
    </>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  );
}
