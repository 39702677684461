import React from "react";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import * as Styled from "./DrawerLogo.styled";
import logo from "../../../../assets/LOGO.PNG";

export default function DrawerLogo() {
  return (
    <Styled.Container>
      <Divider />
      <Styled.Content>
        <Styled.LogoContainer>
          <Styled.Logo src={logo} />
        </Styled.LogoContainer>
        <Styled.LabelContainer>
          <Typography
            sx={{ textTransform: "uppercase" }}
            color="textSecondary"
            variant="body2"
          >
            Metra Avto
          </Typography>
        </Styled.LabelContainer>
      </Styled.Content>
    </Styled.Container>
  );
}
