import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import logo from "../../../assets/LOGO.PNG";

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

export const AvatarContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const Title = (props) => (
  <Typography variant="h5" color="textPrimary">
    {props.children}
  </Typography>
);

export const Avatar = () => (
  <Box>
    <img src={logo} style={{ width: 100 }} alt="logo" />
  </Box>
);
