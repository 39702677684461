import Typography from "@mui/material/Typography";
import * as Styled from "./TradeMark.styled";

export default function TradeMark() {
  return (
    <Styled.Container>
      <Typography variant="caption" color="textPrimary">
        © Galley 2022. Всі права захищені.{" "}
      </Typography>
    </Styled.Container>
  );
}
