import { styled } from "@mui/material/styles";
import { Button as ButtonMaterial, Box, Paper } from "@mui/material";

export const Container = styled(Paper)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    padding: theme.spacing(3),
}));

export const InfoContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    flexGrow: 1,
    cursor: "pointer",
}));

export const InfoImageContainer = styled(Box)(({ theme }) => ({
    width: 120,
    height: 80,
    borderRadius: 5,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

export const InfoImage = styled("img")(({ theme }) => ({
    width: 120,
}));

export const Info = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: theme.spacing(2),
}));

export const ControlsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
        width: "100%",
        marginTop: theme.spacing(2),
    },
}));
export const ModalBox = styled("div")(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    padding: theme.spacing(1),
}));

export const Button = styled(ButtonMaterial)(({ theme }) => ({
    width: 160,
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}));
