import React, { createContext, useContext, useState } from "react";
import {
  signInHandler,
  signUpHandler,
  signOutHandler,
  sendResetPasswordEmailHandler,
  sendEmailVerificationHandler,
} from "../firebase";

let AuthContext = createContext(null);

export default function AuthProvider({ children }) {
  let [user, setUser] = useState(() => {
    return JSON.parse(localStorage.getItem("user"));
  });

  async function signIn(email, password) {
    const result = await signInHandler(email, password);
    const { user } = result;

    if (result.user) {
      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
      return user;
    }
  }

  async function signUp(email, password) {
    const result = await signUpHandler(email, password);
    const { user } = result;

    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
      await sendEmailVerificationHandler(user);
    }
  }

  async function signOut(cb) {
    await signOutHandler();
    localStorage.removeItem("user");
    setUser(null);
    cb();
  }

  async function sendResetPasswordEmail(email) {
    await sendResetPasswordEmailHandler(email);
  }

  let auth = { user, signIn, signUp, signOut, sendResetPasswordEmail };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
