import { Routes, Route } from "react-router-dom";
import SignIn from "../../pages/SignIn/SignIn";
// import SignUp from "../../pages/SignUp/SignUp";
import ResetPassword from "../../pages/ResetPassword/ResetPassword";
import AdvertisimentList from "../../pages/AdvertisimentList/AdvertisimentList";
import EditAdvertisement from "../../pages/EditAdvertisement/EditAdvertisement";
import AddAdvertisement from "../../pages/AddAdvertisement/AddAdvertisement";
import Layout from "../../components/ui/Layout/Layout";

import RequireAuth from "../../components/hoc/RequireAuth";

export default function Router() {
  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      {/* TODO: Enable after implementing verification for new user */}
      {/* <Route path="/registration" element={<SignUp />} /> */}
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/" element={<Layout />} errorElement={<h1>Idi v sraku</h1>}>
        <Route
          index={true}
          element={
            <RequireAuth>
              <AdvertisimentList />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-advertisement/:id"
          element={
            <RequireAuth>
              <EditAdvertisement />
            </RequireAuth>
          }
        />
        <Route
          path="/add-advertisement"
          element={
            <RequireAuth>
              <AddAdvertisement />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  );
}
