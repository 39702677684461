import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../infrastructure/auth/auth";
import { EMAIL_REGEXP } from "../../constants/validation";

import TradeMark from "../../components/ui/TradeMark/TradeMark";
import AuthHeading from "../../components/ui/AuthHeading/AuthHeading";
import InfoModal from "../../components/ui/InfoModal/InfoModal";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import * as Styled from "./ResetPassword.styled";

export default function ResetPassword() {
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const { email } = data;
    try {
      await auth.sendResetPasswordEmail(email);
      setLoading(false);
      setOpen(true);
    } catch (e) {
      setLoading(false);
      setError("auth", {
        type: "auth",
        message: "Здається, облікового запису з такою адресою не існує",
      });
    }
  };

  function onFocusHandler() {
    if (errors && errors.auth) {
      clearErrors("auth");
    }
    return;
  }

  return (
    <>
      <InfoModal
        handleClose={() => setOpen(false)}
        handleConfirm={() => {
          setOpen(false);
          navigate("/login", { replace: true });
        }}
        title="Зміна паролю"
        buttonTitle="Добре"
        open={open}
      >
        Посилання для відновлення паролю, вже надіслано на Вашу електронну пошту
      </InfoModal>
      <Styled.Container sx={{ bgcolor: "background.default" }}>
        <TradeMark />
        <Styled.Paper elevation={3}>
          <AuthHeading>Відновлення пароля</AuthHeading>
          <Styled.DescriptionContainer>
            <Typography color="textPrimary" variant="body2">
              Для скидання пароля ми надішлемо Вам посилання на електронну
              пошту, для скидання пароля перейдіть за посиланням у листі
            </Typography>
            {errors.auth && errors.auth.type === "auth" && (
              <Styled.AuthErrorContainer>
                <Typography variant="body2" color="error">
                  {errors.auth.message}
                </Typography>
              </Styled.AuthErrorContainer>
            )}
          </Styled.DescriptionContainer>
          <Styled.Form onSubmit={handleSubmit(onSubmit)}>
            <Styled.FormContainer>
              <Styled.TextFieldContainer>
                <TextField
                  id="outlined-error"
                  label="Введіть свою електронну пошту"
                  sx={{ width: "100%" }}
                  onFocus={onFocusHandler}
                  {...register("email", {
                    required: true,
                    pattern: EMAIL_REGEXP,
                  })}
                />
                {errors && errors.email && (
                  <Styled.ErrorContainer>
                    {errors.email && errors.email.type === "required" && (
                      <Typography variant="body2" color="error">
                        Обов'язкове поле
                      </Typography>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <Typography variant="body2" color="error">
                        Невірний формат
                      </Typography>
                    )}
                  </Styled.ErrorContainer>
                )}
              </Styled.TextFieldContainer>

              <Styled.SendButtonConatiner>
                <Styled.SendButton loading={loading}>
                  Надіслати посилання
                </Styled.SendButton>
              </Styled.SendButtonConatiner>

              <Styled.SignInLinkContainer>
                <Link to="/login" component={RouterLink} variant="body2">
                  Повернутися на сторінку входу
                </Link>
              </Styled.SignInLinkContainer>
            </Styled.FormContainer>
          </Styled.Form>
        </Styled.Paper>
      </Styled.Container>
    </>
  );
}
