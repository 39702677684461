import React, { useState } from "react";
import { Link as RouterLink, Outlet, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import ListIcon from "@mui/icons-material/FormatListNumbered";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import ProfileMenu from "../ProfileMenu/ProfileMenu";
import DrawerLogo from "./components/DrawerLogo";

import * as Styled from "./Layout.styled";

export default function Layout() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();

  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const menuItems = [
    { title: "Cписок оголошень", path: "/", icon: <ListIcon /> },
    {
      title: "Додати оголошення",
      path: "/add-advertisement",
      icon: <AddIcon />,
    },
  ];

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  return (
    <Styled.Root>
      <CssBaseline />
      <Styled.AppBar position="fixed">
        <Styled.AppBarContainer>
          <Styled.Toolbar>
            <Styled.MenuButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </Styled.MenuButton>
            <Typography variant="h6" noWrap>
              Панель адміністратора
            </Typography>
          </Styled.Toolbar>
          <ProfileMenu />
        </Styled.AppBarContainer>
      </Styled.AppBar>
      <Styled.Drawer
        variant={isMdUp ? "permanent" : "temporary"}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
      >
        <Styled.DrawerContainer>
          <List>
            {menuItems.map(({ path, title, icon }) => (
              <ListItem
                button
                key={path}
                selected={location.pathname === path}
                to={path}
              >
                <Button
                  onClick={() => setOpen(false)}
                  startIcon={icon}
                  LinkComponent={RouterLink}
                  to={path}
                >
                  {title}
                </Button>
              </ListItem>
            ))}
          </List>
          <DrawerLogo />
        </Styled.DrawerContainer>
      </Styled.Drawer>
      <Styled.Content>
        <Outlet />
      </Styled.Content>
    </Styled.Root>
  );
}
